import React from 'react'
import { Card as CardPR } from 'primereact/card'

import css from './card.module.css'

export default function Card({ children, title }) {
  return (
    <CardPR className={css.card}>
      {title && <h3>{title}</h3>}
      {children}
    </CardPR>
  )
}
