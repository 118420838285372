import React from 'react'

import Icon from '../icon/icon'

import css from './global-banner.module.css'

export default function GlobalBanner({
  onClick,
  category = 'reminder',
  text,
  subtext
}) {
  const content = (
    <div className={`${css.banner} ${css[category]}`}>
      <span>{text}:</span>
      {subtext}
    </div>
  )

  return (
    <div  
      id="globalBanner"
      className={`${css.globalBanner}`}
      data-component="global-banner"
    >
      {content}

      <div className={css.close} onClick={() => onClick(false)}>
        <Icon icon="X" fill='#fff' />
      </div>
    </div>
  )
}
