/** @jsxImportSource @emotion/react */

import React, { useContext, useState, useEffect, useCallback, useRef } from "react";
import { useMutation } from "../../hooks";
import Client from "../../client";
import * as Yup from "yup";
import FormTextarea from '../../components/form-textarea/form-textarea'
import Button from "../../components/button/button";
import { css } from "./css";
import { changeReloadState } from "../../store/noteSlice";


export default function NoteEntryComponent(props) {

  const {
    onFetchMore,
    horse
  } = props;

  
  const textareaRef = useRef(null);

  const [message, setMessage] = useState(null);

  useEffect(() => {
    setMessage("")
  }, [horse])

  const handleSubmit = async () => {
    const payload = {
      note_content: message,
      horse_id: horse.id
    };

    try {
      const response = await Client.post("/notes", payload);
      changeReloadState(true);
      setMessage('');
      onFetchMore();
    }
    catch (err) {
      alert(
        "Error occured trying to create a note."
      );
    }
  };

  return (
    <>
      <div className="formInput" css={css.form}>
         <FormTextarea
          element="input"
          elementType="text"
          id="message"
          name="message"
          label="Add note..."
          placeholder="Add note..."
          rows="8"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        />
      </div>
      <Button
        label="Save Note"
        onClick={handleSubmit}
        type="type"
        style={{ width: '100%' }}
      />
    </>
  );
};