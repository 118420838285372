import React from 'react'
import { useField } from 'formik'
import { Dropdown as DropdownPR } from 'primereact/dropdown'

import { FormErrorMessage } from '../../form-error-message/form-error-message'

import css from '../form-element.module.css'

export default function Dropdown({
  label,
  required,
  formik,
  size,
  ...props
}) {

  const [field, meta] = useField(props?.name)
  const elWidth = `${size || '100'}%`

  const getStyles = () => {
    let styles = {}
    if (props.style) {
      styles = {
        ...props.style
      }
    }
    return styles
  }

  const getLabel = label && (
    <label>
      {label}
      {required && <span>*</span>}
    </label>
  )

  const getElement = (
    <DropdownPR
      {...field}
      {...props}
    />
  )
  
  return (
    <div
      className={`${css.formEl} ${meta.error && meta.touched ? css.error : ''}`}
      style={{
        width: elWidth,
        ...getStyles()
      }}
    >
      {getLabel}
      {getElement}
      {required && <FormErrorMessage name={props.name} formik={formik} />}
    </div>
  )
}
