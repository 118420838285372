/** @jsxImportSource @emotion/react */

import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { confirmDialog } from 'primereact/confirmdialog'

import { TOP_LEVEL_PAGES } from '../constants'
import AuthContext from '../../context/auth-context'
import { useQuery } from '../../hooks'

import ConfirmDialog from '../confirm-dialog/confirm-dialog'
import Button from '../button/button'
import Icon from '../icon/icon'

// import MobTrigger from './menu-trigger'
import MenuSettings from './menu-settings/menu-settings'

import css from './menu.module.css'

function Menu({ activePage, setActivePage, navOpen, setNavOpen, hideMenu }) {
  const navigate = useNavigate()

  const settingsRef = useRef(null)

  const { logout, userData, selectCompany } = useContext(AuthContext)
  const { data, refetch } = useQuery('/barns')
  const [barnId, setBarnId] = useState(null)
  const [barnOptions, setBarnOptions] = useState([])
  const [settingsOpen, setSettingsOpen] = useState(false)

  // const trigger = <MobTrigger navOpen={navOpen} setNavOpen={setNavOpen} />

  const showBarnOptions = userData?.is_admin || userData?.user_to_barn?.length > 0 ? true : false
  useEffect(() => {
    const opts = data?.data
      ?.filter(
        b =>
          userData?.is_admin ||
          userData?.user_to_barn?.find(b2 => b.id === b2.barn_id),
      )
      .sort((a,b) => a.name > b.name ? 1 : -1)
      .map(b => {
        return {
          value: b.id,
          label: b.name,
        }
      })
    if (userData?.is_admin && !userData?.barn_id) {
      if (opts?.length > 0) {
        const user = { ...userData }
        user.barn_id = opts[0].value
        user.barn_name = opts[0].label
        selectCompany(user)
        setBarnId(user.barn_id)
      }
    } else if(!userData?.barn_id && opts?.length > 0) {
      const user = { ...userData }
      user.barn_id = opts[0].value
      selectCompany(user)
      setBarnId(user.barn_id)
    }
    if(userData.barn_id) {
      setBarnId(userData.barn_id)
    }
    setBarnOptions(opts)
  }, [data, userData])

  useEffect(() => {
    const handleClickOutside = event => {
      if (settingsRef.current && !settingsRef.current.contains(event.target)) {
        setSettingsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [settingsOpen])

  const getIcon = icon => {
    if (!icon) return null

    return <Icon icon={icon} />
  }

  const handleSettings = () => {
    setSettingsOpen(!settingsOpen)
  }

  const pageEls = !hideMenu
    ? TOP_LEVEL_PAGES.filter(
        p => userData.is_admin || p.visibility.find(r => userData[r] || r === 'is_guardian'),
      ).map(page => {
        return (
          <li
            key={page?.path}
            onClick={() => {
              setNavOpen(false)
              navigate(`/${page?.path}`)
              setActivePage(page?.path)
              setSettingsOpen(false)
            }}
            className={activePage === page?.path ? `active ${css.active}` : ''}
          >
            {getIcon(page?.icon)}
            {page?.display}
          </li>
        )
      })
    : null

  const checkLogout = () => {
    confirmDialog({
      group: 'headless',
      message: `Are you sure you want to logout, ${userData?.first_name} ${userData?.last_name}?`,
      position: 'top-right',
      className: css.confirmDialog,
    })
  }

  const dialogContent = ({ headerRef, contentRef, footerRef, hide, message }) => (
    <div className={css.confirmDialog}>
      {message?.header && (
        <span className={css.confirmDialog?.content} ref={headerRef}>
          {message.header}
        </span>
      )}

      <div className={css.confirmDialog?.content}>
        <p className="mb-0" ref={contentRef}>
          {message.message}
        </p>
      </div>

      <div className={css.confirmDialog?.footer} ref={footerRef}>
        <Button
          label='Go Back'
          variant='outlined'
          onClick={event => {
            hide(event)
          }}
        >{message?.rejectLabel}</Button>

        <Button
          label='Logout'
          color="danger"
          onClick={event => {
            hide(event)
            logout()
          }}
        />
      </div>
    </div>
  )

  const navigation = (
    <nav className={`${css.navigation} ${navOpen ? css.open : ''} ${settingsOpen ? css.open : ''}`}>
      <ul>
        {pageEls}
      </ul>
    </nav>
  )

  const settings = (
    <div className={`${css.settings} ${settingsOpen ? css.open : ''}`}>
      {settingsOpen && <div className={css.backdrop} onClick={() => setSettingsOpen(false)} />}

      <div className={css.hours}>
        <p>Barn hours:</p>
        <p>7a - 9p daily</p>
      </div>

      <Button
        label=" "
        icon="pi pi-chevron-down"
        onClick={handleSettings}
      />
      
      {settingsOpen && (
        <MenuSettings
          logout={checkLogout}
          options={barnOptions}
          value={barnId}
          onChange={it => {
            const user = { ...userData }
            user.barn_id = it.target.value
            selectCompany(user)
            setBarnId(user.barn_id)
            setSettingsOpen(false)
          }}
        />
      )}
    </div>
  );


  const confirmBlock = (
    <ConfirmDialog
      group="headless"
      content={dialogContent}
    />
  )

  return (
    <>
      {/* {trigger} */}
      {navigation}
      {showBarnOptions && settings}
      {confirmBlock}
    </>
  )
}

export default Menu
