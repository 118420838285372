import React from 'react'
import { useField } from 'formik'
import { Password as PRPassword } from 'primereact/password'

import { FormErrorMessage } from '../../form-error-message/form-error-message'

import css from '../form-element.module.css'

const Password = ({
  label,
  required,
  formik,
  size,
  ...props
}) => {

  const [field, meta] = useField(props?.name)
  
  const elWidth = `${size || '100'}%`

  const getLabel = label && (
    <label>
      {label}
      {required && <span>*</span>}
    </label>
  )

  const getElement = (
    <PRPassword
      {...field}
      feedback={false}
      toggleMask
      {...props}
    />
  )
  
  return (
    <div
      className={`${css.formEl} ${meta.error && meta.touched ? css.error : ''}`}
      style={{ width: elWidth }}
    >
      {getLabel}
      {getElement}
      {required && <FormErrorMessage name={props.name} formik={formik} />}
    </div>
  )
}

export default Password
