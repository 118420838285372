import { keyframes } from '@emotion/react'
import {
  borderBottomLeftRadius,
  // borderRadius,
  // dimensions,
  flexbox,
  fontbox,
  // fullHeight,
  fullWidth,
  paddingX,
  paddingXY,
  paddingY,
  posAbsolute,
  posFixed,
  posRelative,
  posStatic,
  square,
  // transition,
} from '../../emotion/utils'

import { appColors } from '../../emotion/appColors'
import { appFonts } from '../../emotion/appFonts'

const fadeOutAndMove = keyframes`
  0% {
    opacity: 0;
    margin-right: 0;
  }
  50% {
    opacity: 0;
    //margin-right: 600px;
  }
  100% {
    opacity: 1;
    //margin-right: 600px;
  }
`

const breakpoints = [
  320, 400, 450, 500, 576, 650, 700, 768, 850, 900, 992, 1100, 1200, 1300, 1400,
  1500, 1600, 1700, 1800, 1900,
]

// const imgBlockHeight = {
//   0: 140,
//   320: 150,
//   400: 195,
//   450: 222,
//   500: 102,
//   576: 130,
//   650: 144,
//   700: 158,
//   768: 108,
//   850: 124,
//   900: 132,
//   992: 150,
//   1100: 118,
//   1200: 132,
//   1300: 146,
//   1400: 160,
//   1500: 174,
//   1600: 188,
//   1700: 200,
//   1800: 210,
//   1900: 230,
// }
const imgBlockHeightOpen = {
  0: 122,
  320: 150,
  400: 195,
  450: 222,
  500: 102,
  576: 122,
  650: 136,
  700: 148,
  768: 168,
  850: 190,
  900: 110,
  992: 134,
  1100: 100,
  1200: 118,
  1300: 102,
  1400: 122,
  1500: 174,
  1600: 188,
  1700: 200,
  1800: 210,
  1900: 230,
}

const mediaQueries = heights =>
  breakpoints.reduce((acc, bp) => {
    acc[`@media screen and (min-width: ${bp}px)`] = {
      height: heights?.[bp],
    }
    return acc
  }, {})

export const css = {
  container: activeStall => ({
    height: activeStall ? 'calc(100vh - 60px)' : 'auto',
    // overflowY: activeStall ? 'hidden' : null,
  }),

  activeStall: {
    ...flexbox('row', 'flex-start', 'space-between', 0),
    ...fullWidth(),
    height: 'calc(100vh - 60px)',
    overflow: 'hidden',
  },

  cardSection: {
    // ...fullWidth(),
    // ...paddingY(16),
    // ...paddingX(8),
    // display: 'grid',
    // gap: 16,
    // gridTemplateColumns: '1fr',
    // transition: 'margin-right 0.25s ease-in-out',

    // '@media screen and (min-width: 500px)': {
    //   ...paddingXY(24),
    //   gridTemplateColumns: 'repeat(2, 1fr)',
    // },
    // '@media screen and (min-width: 768px)': {
    //   gridTemplateColumns: 'repeat(3, 1fr)',
    // },

    // '@media screen and (min-width: 1100px)': {
    //   gap: 24,
    //   gridTemplateColumns: 'repeat(4, 1fr)',
    // },

    '&.tray-open': {
      display: 'none',
      gridTemplateColumns: 'repeat(1, 1fr)',
      height: '100%',

      '@media screen and (min-width: 600px)': {
        ...flexbox('column', 'flex-start', 'flex-start', 16),
        flex: 1,
        overflowY: 'scroll',
        width: '50%',
      },
      '@media screen and (min-width: 900px)': {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      '@media screen and (min-width: 1100px)': {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },

      '> div': {
        ...fullWidth(),

        '> div': {
          '> div': {
            '&:nth-of-type(2)': {
              ...mediaQueries(imgBlockHeightOpen),
              height: imgBlockHeightOpen?.[0],
            },
          },
        },
      },
    },
  },

  tray: {
    // ...fullHeight(),
    // ...fullWidth(),
    // ...paddingXY(8),
    // ...posRelative(),
    // backgroundColor: appColors?.grays?.[0],
    // overflowY: 'scroll',

    // '@media screen and (min-width: 500px)': {
    //   ...paddingXY(16),
    // },
    // '@media screen and (min-width: 600px)': {
    //   backgroundColor: appColors?.grays?.[50],
    // },

    // x: {
    //   // ...posAbsolute(6, 5, null, null, 1),
    //   // cursor: 'pointer',

    //   // '@media screen and (min-width: 600px)': {
    //   //   display: 'none',
    //   // },
    // },

    // '&.open': {
    //   ...fullWidth(),

    //   '@media screen and (min-width: 600px)': {
    //     width: '50%',
    //   },
    //   '@media screen and (min-width: 900px)': {
    //     width: 375,
    //   },
    //   '@media screen and (min-width: 1300px)': {
    //     width: 566,
    //   },
    // },

    // controls: {
    //   close: {
    //     // ...dimensions(25, 52),
    //     // cursor: 'pointer',
    //     // display: 'none',

    //     // '@media screen and (min-width: 600px)': {
    //     //   ...posAbsolute(80, '48%', null, null, 10),
    //     //   display: 'block',
    //     // },
    //     // '@media screen and (min-width: 768px)': {
    //     //   right: '49%',
    //     // },
    //     // '@media screen and (min-width: 900px)': {
    //     //   right: 370,
    //     // },
    //     // '@media screen and (min-width: 1300px)': {
    //     //   right: 560,
    //     // },

    //     // '> div': {
    //     //   '&:last-of-type': {
    //     //     ...posAbsolute(14, null, null, 2),

    //     //     svg: {
    //     //       ...square(24),
    //     //     },
    //     //   },
    //     // },
    //   },
    // },

    closeButton: {
      ...borderBottomLeftRadius(4),
      ...flexbox('column', 'center', 'center'),
      ...posAbsolute(60, 0, null, null, 100),
      ...square(50),
      background: appColors?.reds?.destructive,
      border: 'none',
      cursor: 'pointer',
      zIndex: 1000,
    },

    container: {
      ...flexbox('column', 'flex-start', 'flex-start', 16),
      ...paddingXY(0),
      ...posRelative(),
      background: appColors.grays[0],
      borderRadius: '8px',
      paddingTop: 35,
      paddingBottom: 60,

      '@media screen and (min-width: 500px)': {
        paddingBottom: 112,
      },
      '@media screen and (min-width: 600px)': {
        ...paddingXY(16),
      },

      '> *': {
        ...fullWidth(),
      },
    },

    actions: {
      ...paddingX(8),
      ...posFixed(null, 0, 0, 0, 10),
      backgroundColor: appColors?.grays?.[0],
      paddingTop: 8,
      paddingBottom: 16,

      '@media screen and (min-width: 600px)': {
        ...paddingXY(0),
        ...posStatic(),
      },
    },
  },

  stallCard: isActive => ({
    // ...borderRadius(8),
    // ...fullWidth(),
    // ...paddingXY(16),
    // border: `2px solid ${isActive ? appColors?.blues?.primary?.[200] : appColors?.grays?.[50]}`,
    // boxShadow: '0px 4px 8px 0px rgba(25, 53, 66, 0.08)',

    // '@media screen and (min-width: 500px)': {
    //   maxWidth: 393,
    // },

    // holder: {
    //   // ...flexbox('column', 'flex-start', 'flex-start', 8),
    //   // ...posRelative(),

    //   date: {
    //     ...borderRadius(3),
    //     ...fontbox(appFonts?.inter, 10, 600, 12),
    //     ...paddingX(4),
    //     ...paddingY(2),
    //     ...posAbsolute(null, null, 3, 3, 10),
    //     backgroundColor: `${appColors?.grays?.[0]}50`,
    //     color: appColors?.grays?.[0],
    //     textShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
    //   },
    // },

    '.tray-open &': {
      width: 'calc(50% - 16px)', // Adjusted width when tray-open
      animation: `${fadeOutAndMove} 0.5s ease-in-out forwards`,

      '@media (max-width: 1000px)': {
        width: 'calc(100% - 16px)', // 2 cards across
      },
    },

    date: {
      ...fontbox(appFonts?.inter, 10, 400, 14),
      marginTop: 'auto',
    },
  }),

  stallCardHeader: {
    ...flexbox('row', 'center', 'space-between', 12),
    ...paddingY(16),

    h1: {
      ...fontbox(appFonts.inter, 14, 700, 14),
      color: appColors.grays[900],
    },

    p: {
      ...fontbox(appFonts.inter, 14, 400, 14),
      color: appColors.grays[900],
    },
  },

  stallNoteSection: {
    ...flexbox('column', 'start', 'space-between', 12),
    padding: '0px 0px 8px 0px',
    width: '100%',

    h3: {
      ...fontbox(appFonts.inter, 14, 700, 14),
      color: appColors.grays[900],
    },
    p: {
      ...fontbox(appFonts.inter, 14, 400, 14),
      color: appColors.grays[900],
    },
  },

  stallNotes: theme => ({
    width: '100%',
  }),

  stallNoteEntry: {
    ...fullWidth(),
  },

  modal: {
    footer: {
      ...flexbox('row', 'center', 'space-between', 16),

      '> *': {
        flex: 1,
        margin: 0,
      },
    },

    requestVet: {
      ...flexbox('column', 'flex-start', 'flex-start', 16),

      '> *': {
        ...fullWidth(),
      },

      p: {
        textAlign: 'center',
      },
    },
  },
}
