import React from 'react'
import { useField } from 'formik'
import { InputTextarea as PRInputTextarea } from 'primereact/inputtextarea'

import { FormErrorMessage } from '../../form-error-message/form-error-message'

import css from '../form-element.module.css'

const InputTextarea = ({
  label,
  required,
  formik,
  size,
  ...props
}) => {

  const [field, meta] = useField(props?.name)
  const elWidth = `${size || '100'}%`

  const getStyles = () => {
    let styles = {}
    if (props.style) {
      styles = {
        width: elWidth,
        ...props.style
      }
    }
    return styles
  }
  
  const getLabel = label && (
    <label>
      {label}
      {required && <span>*</span>}
    </label>
  )
  
  const getElement = <PRInputTextarea {...field} {...props} />

  return (
    <div
      className={`${css.formEl} ${meta.error && meta.touched ? css.error : ''}`}
      style={{ ...getStyles() }}
    >
      {getLabel}
      {getElement}
      {required && <FormErrorMessage name={props.name} formik={formik} />}
    </div>
  )
}

export default InputTextarea
