import React from 'react'

import { getCcMonths, getCcYears } from '../../utils'
import FormElement from '../form-element/form-element'

import cssFormElement from '../form-element/form-element.module.css'
import css from './form-cc-expiration.module.css'

export default function FormCcExpiration({
  id,
  label,
  required,
  formik,
  size
}) {

  const elWidth = `${size || '100'}%`
  const months = getCcMonths
  const years = getCcYears

  const getLabel = label && (
    <label>
      {label}
      {required && <span>*</span>}
    </label>
  )

  const getElement = formik => (
    <div id={id} className={css.formEl}>
      <FormElement
        element='dropdown'
        id='ccExpMonth'
        name='ccExpMonth'
        label=''
        formik={formik}
        options={months}
        required
        size={40}
      />

      <FormElement
        element='dropdown'
        id='ccExpYear'
        name='ccExpYear'
        label=''
        formik={formik}
        options={years}
        required
        size={60}
      />
    </div>
  )

  return (
    <div
      className={`${cssFormElement.formEl}`}
      style={{ width: elWidth }}
    >
      {getLabel}
      {getElement(formik)}
    </div>
  )
}
