import React from 'react'

import {
  Calendar,
  Dropdown,
  Input,
  InputTextarea,
  Password
} from './elements'

const FormElement = ({
  element,
  ...props
}) => {
  const components = {
    'calendar': Calendar,
    'dropdown': Dropdown,
    'input': Input,
    'inputTextarea': InputTextarea,
    'password': Password,
  }

  const getFormElement = type => {
    const Component = components[type]

    return <Component {...props} />
  }

  return <>{getFormElement(element)}</>
}

export default FormElement
