/** @jsxImportSource @emotion/react */
import React, { useContext, useState, useEffect } from 'react'
import { useQuery } from '../../hooks'
import { appColors } from '../../emotion/appColors'

import AuthContext from '../../context/auth-context'

import { Button, GlobalBanner, Icon, NoDataScreen, Sidebar } from '../../components'

import StallSection from './stall-section/stall-section'
import StallTray from './stall-tray'
import ModalRequestVet from './components/modal-request-vet'

import { css as emotion } from './css'
import css from './stalls.module.css'

function Stalls() {
  const [activeStall, setActiveStall] = useState(null)
  const [activeHorse, setActiveHorse] = useState(null)
  const [showModalRequestVet, setShowModalRequestVet] = useState(false)
  const [showBanner, setShowBanner] = useState(false)
  const [showSidebar, setShowSidebar] = useState(false)

  const { data, refetch } = useQuery(`/stalls`)

  const { userData } = useContext(AuthContext)

  useEffect(() => {
    //Refresh the activeStall if we got the stall data again
    if (data && activeStall) {
      const newActive = data.data.find(s => s.id === activeStall.id)
      if (newActive) setActiveStall(newActive)
      else setActiveStall(null)
    }
  }, [data])

  //If user data changes (change barn they are looking at), refresh
  useEffect(() => {
    refetch()
  }, [refetch, userData])

  const onStallClick = (stall) => {
    setShowSidebar(true)
    setActiveStall(stall)
  }

  const handleRequestVet = () => {
    console.log('request vet functionality')
    alert('Request vet functionality')
  }

  const getContainerStyles = emotion?.container(activeStall)

  const btnRequestVet = (
    <Button
      key='requestVet'
      label='Request vet'
      onClick={handleRequestVet}
    />
  )

  const propsSidebar = ({
    id: css.sidebar,
    footerbuttons: [btnRequestVet],
    footerButtonsInside: true,
    // headertitle: 'Stalls',
    onHide: () => setActiveStall(null),
    visible: showSidebar,
    dismissable: false,
    modal: false,
    blockScroll: true,
  })

  // console.log('activeStall', activeStall)
  const sidebar = (
    <Sidebar {...propsSidebar}>
      {showBanner && (
        <GlobalBanner
          onClick={() => setShowBanner(false)}
          category="reminder"
          text="Appointment reminder"
          subtext="Vet checkup for Daisy at 1:30pm today"
        />
      )}
      
      <div className={css.stallView}>
        <StallTray
          activeStall={activeStall}
          setActiveStall={setActiveStall}
          refetchStall={refetch}
          setActiveHorse={setActiveHorse}
          activeHorse={activeHorse}
          requestVet={setShowModalRequestVet}
          footerButtonsInside={propsSidebar?.footerButtonsInside}
        />
      </div>
    </Sidebar>
  )

  const handleClose = () => {
    setShowModalRequestVet(false)
  }

  const modals = (
    <>
      <ModalRequestVet
        visible={showModalRequestVet}
        close={() => handleClose()}
        user={userData}
        horse={activeHorse}
      />
    </>
  )

  const msg = !data?.data
    ? 'Please select a stable to view stalls.'
    : 'No stalls found.'

  if (!data?.data || data?.data.length === 0) {
    return <NoDataScreen icon="Logo" msg={msg} />
  }

  return (
    <div
      id="stalls"
      className={`${css.stalls} ${activeStall ? 'active' : ''}`}
      css={getContainerStyles}
    >
      <StallSection
        data={data?.data}
        setActiveStall={onStallClick}
        activeStall={activeStall}
        // className={activeStall ? 'tray-open' : ''}
      />

      {activeStall && sidebar}

      {modals}
    </div>
  )
}

export default Stalls
