import React from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { getCcStates } from '../../utils'

import { FormCcExpiration, FormElement } from '../../components'
import Button from '../../components/button/button'

export default function TrainersPayment({ onHide, onClick }) {

  const states = getCcStates

  const initialValues = {
    ccName: '',
    ccNumber: '',
    ccExpMonth: '',
    ccExpYear: '',
    ccCVV: '',
    ccAddress: '',
    ccAddress2: '',
    ccCity: '',
    ccState: '',
    ccZip: '',
    notes: ''
  }

  const validationSchema = Yup.object({
    ccName: Yup.string().label('Name on card').required(),
    ccNumber: Yup.string().label('Card number').required(),
    ccExpMonth: Yup.string().label('Expiry Month').required(),
    ccExpYear: Yup.string().label('Expiry Year').required(),
    ccCVV: Yup.string().label('CVV').required(),
    ccAddress: Yup.string().label('Billing address').required(),
    ccAddress2: Yup.string().label('Address line 2'),
    ccCity: Yup.string().label('City').required(),
    ccState: Yup.string().label('State').required(),
    ccZip: Yup.string().label('Zip').required(),
    notes: Yup.string().label('Notes')
  })

  const onSubmit = () => {}
  
  const formikProps = {
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validationSchema
  }

  return (
    <Formik {...formikProps}>
      {(formik) => {

        const sep = <div data-hw-role='sep' />

        const ccName = (
          <FormElement
            element='input'
            id='ccName'
            name='ccName'
            label='Name on card'
            formik={formik}
            required
          />
        )

        const ccNumber = (
          <FormElement
            element='input'
            id='ccNumber'
            name='ccNumber'
            label='Card number'
            formik={formik}
            required
          />
        )

        const ccExpiration = (
          <FormCcExpiration
            id='ccExpiration'
            label='Expiry'
            formik={formik}
            required
            size={33}
          />
        )

        const ccCVV = (
          <FormElement
            element='password'
            id='ccCVV'
            name='ccCVV'
            label='CVV'
            formik={formik}
            required
            size={33}
            keyfilter={/[^a-z,^A-Z]/}
            maxLength={4}
          />
        )

        const ccAddress = (
          <FormElement
            element='input'
            id='ccAddress'
            name='ccAddress'
            formik={formik}
            label='Billing address'
            required
          />
        )

        const ccAddress2 = (
          <FormElement
            element='input'
            id='ccAddress2'
            name='ccAddress2'
            formik={formik}
            label='Address line 2'
          />
        )

        const ccCity = (
          <FormElement
            element='input'
            id='ccCity'
            name='ccCity'
            formik={formik}
            label='City'
            required
          />
        )

        const ccState = (
          <FormElement
            element='dropdown'
            id='ccState'
            name='ccState'
            formik={formik}
            label='State'
            options={states}
            required
            size={47}
          />
        )

        const ccZip = (
          <FormElement
            element='input'
            id='ccZip'
            name='ccZip'
            formik={formik}
            label='Zip'
            required
            size={47}
            style={{ marginRight: 'auto' }}
          />
        )

        const notes = (
          <FormElement
            element='inputTextarea'
            id='notes'
            name='notes'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.notes}
            label='Notes'
          />
        )

        const formEls = [
          ccName,
          ccNumber,
          ccExpiration,
          ccCVV,

          sep,
          ccAddress,
          ccAddress2,
          ccCity,
          ccState,
          ccZip,
          
          sep,
          notes
        ]

        const getContent = formEls && formEls.map((el, index) => (
          <React.Fragment key={index}>
            {el}
          </React.Fragment>
        ))

        const body = (
          <div>
            <Button
              label='Use a saved payment method'
              onClick={onClick}
              color='tertiary'
            />

            {getContent}
          </div>
        )

        const btnCancel = (
          <Button
            key="btnCancel"
            onClick={onHide}
            label="Cancel"
            color='secondary'
          />
        )
        
        const btnConfirm = (
          <Button
            key="btnConfirm"
            onClick={() => alert('Confirm functionality')}
            label="Confirm"
          />
        )

        const footerbuttons = [btnCancel, btnConfirm]

        const footer = (
          <footer>
            {footerbuttons.map((button, index) => (
              <React.Fragment key={index}>
                {button}
              </React.Fragment>
            ))}
          </footer>
        )

        return (
          <Form>
            {body}
            {footer}
          </Form>
        )
      }}
    </Formik>
  )
}
