import lanaSteiner from './images/trainers/lana-steiner.png'
import phoenixBaker from './images/trainers/phoenix-baker.png'
import andiLane from './images/trainers/andi-lane.png'
import kateMorrison from './images/trainers/kate-morrison.png'
import drewCano from './images/trainers/drew-cano.png'

export const trainers = [
  {
    id: 1,
    fname: 'Lana',
    lname: 'Steiner',
    email: 'lanas@themainbranch.com',
    avatar: lanaSteiner,
    about: 'Some information about their expertise and things they train on.'
  },
  {
    id: 2,
    fname: 'Phoenix',
    lname: 'Baker',
    email: 'phoenixb@themainbranch.com',
    avatar: phoenixBaker,
  },
  {
    id: 3,
    fname: 'Andi',
    lname: 'Lane',
    avatar: andiLane,
    email: 'andil@themainbranch.com',
    about: 'Some information about their expertise and things they train on, their training style etc etc...Ut blandit amet ipsum neque varius. Lorem ac ornare facilisis eget fermentum mi. Vitae consequat ac sit adipiscing fringilla consectetur.'
  },  
  {
    id: 4,
    fname: 'Kate',
    lname: 'Morrison',
    email: 'katem@themainbranch.com',
    avatar: kateMorrison,
  },
  {
    id: 5,
    fname: 'Drew',
    lname: 'Cano',
    avatar: drewCano,
    email: 'drewc@themainbranch.com',
    about: 'Some information about their expertise and things they train on, their training style etc etc...Ut blandit amet ipsum neque varius. Lorem ac ornare facilisis eget fermentum mi. Vitae consequat ac sit adipiscing fringilla consectetur.'
  },
  {
    id: 6,
    fname: 'Adrian',
    lname: 'Mueller',
    email: 'Adrian27@themainbranch.com',
  }
]

export const barnNotes = [
  'Viewing area is COLD! Heater is being looked at, but in the meantime dress warmly',
  'Please clean up after your ride so the footing doesn\'t get damaged!',
  'Elementum turpis faucibus velit laoreet. Viverra ac sed massa et dui risus.',
  'Commodo consectetur nibh vitae sed nibh. Phasellus facilisi diam ut pellentesque lobortis.',
  'Mi sem sapien netus suspendisse vitae quisque tincidunt turpis nunc. In ut venenatis mollis ultrices scelerisque ultrices est sit velit.'
]

export const events = [
  {
    title: 'All Day Event',
    start: '2025-02-10',
    extendedProps: {
      notification: '30 minutes before',
      trainer: 'John Doe',
      location: 'Main Hall',
      description: 'Dinner with friends',
      category: 'afterwork'
    },
  },
  {
    title: 'Long Event',
    start: '2025-01-07',
    end: '2025-02-10',
    extendedProps: {
      trainer: 'John Doe',
      location: 'Home',
      description: 'Vacation',
      category: 'pto'
    },
  },
  {
    groupId: 999,
    title: 'Repeating Event',
    start: '2025-02-10T16:00:00',
    extendedProps: {
      notification: '10 minutes before',
      trainer: 'Jane Doe',
      location: 'Conference Room',
      description: 'Standup',
      category: 'meeting',
      invites: [
        {
          fname: 'John',
          lname: 'Doe',
          status: 'accepted',
        },
        {
          fname: 'Jane',
          lname: 'Doe',
          status: 'accepted',
        },
        {
          fname: 'John',
          lname: 'Smith',
          status: 'declined',
        },
        {
          fname: 'Alice',
          lname: 'Johnson',
          status: 'pending',
        },
        {
          fname: 'Bob',
          lname: 'Brown',
          status: 'pending',
        },
        {
          fname: 'Charlie',
          lname: 'Davis',
          status: 'pending',
        }
      ],
    },
  },
  {
    groupId: 999,
    title: 'Repeating Event',
    start: '2025-02-17T16:00:00',
    extendedProps: {
      notification: '10 minutes before',
      trainer: 'Jane Doe',
      location: 'Conference Room',
      description: 'Standup',
      category: 'meeting'
    },
  },
  {
    title: 'Conference',
    start: '2025-02-11',
    end: '2025-02-13',
    extendedProps: {
      notification: '10 minutes before',
      trainer: 'Jane Doe',
      location: 'Conference Room',
      description: 'Standup',
      category: 'meeting'
    },
  },
  {
    title: 'Meeting',
    start: '2025-02-12T10:30:00',
    end: '2025-02-12T12:30:00',
    extendedProps: {
      notification: '10 minutes before',
      trainer: 'Jane Doe',
      location: 'Outside the office',
      description: 'Meeting to go over the project',
      category: 'meeting'
    },
  },
  {
    title: 'Lunch Meeting',
    start: '2025-02-12T12:00:00',
    extendedProps: {
      notification: '20 minutes before',
      trainer: 'Jane Doe',
      location: 'Conference Room',
      description: 'Lunch meeting with clients',
      category: 'meeting'
    },
  },
  {
    title: 'Meeting',
    start: '2025-02-12T14:30:00',
  },
  {
    title: 'Happy Hour',
    start: '2025-02-12T17:30:00',
    extendedProps: {
      notification: '10 minutes before',
      trainer: 'John Doe',
      location: 'All the bars',
      description: 'Bar crawl with coworkers',
      category: 'afterwork'
    },
  },
  {
    title: 'Dinner',
    start: '2025-02-12T20:00:00',
    end: '2025-02-12T23:00:00',
    extendedProps: {
      notification: '30 minutes before',
      trainer: 'John Doe',
      location: 'Main Hall',
      description: 'Dinner with friends',
      category: 'afterwork'
    },
  },
  {
    title: 'Birthday Party',
    start: '2025-02-13T07:00:00',
    extendedProps: {
      notification: '30 minutes before',
      trainer: 'John Doe',
      location: 'Main Hall',
      description: 'Dinner with friends',
      category: 'afterwork'
    },
  },
  {
    title: 'Click for Google',
    url: 'http://google.com/',
    start: '2025-02-28',
    extendedProps: {
      notification: '30 minutes before',
      trainer: 'John Doe',
      location: 'Main Hall',
      description: 'Dinner with friends',
      category: 'default'
    },
  },
]

export const weatherData = {
  center: {
    temps: {
      highTemp: {
        value: 45,
        icon: 'ArrowUp',
        degree: true,
      },
      lowTemp: {
        value: 15,
        icon: 'ArrowDown',
        degree: true,
      },
    },
    suns: {
      sunrise: {
        value: '7:18a',
        icon: 'Sunrise',
      },
      sunset: {
        value: '4:38p',
        icon: 'Sunset',
      },
    },
    moons: {
      moonrise: {
        value: '11:32a',
        icon: 'Moonrise',
      },
      moonset: {
        value: '12:27a',
        icon: 'Moonset',
      },
    },
    phase: {
      moonphase: {
        value: '58% Waxing Gibbous',
        icon: 'Moon2',
      },
    },
  },
  right: {
    conditionStats: {
      api: {
        value: 27,
        label: 'AQI',
      },
      uv: {
        value: 2,
        label: 'UV',
      },
    },
    conditions: {
      wind: {
        value: 'NE4 mph',
        icon: 'Wind2',
      },
      precipChance: {
        value: '30%',
        icon: 'Droplets',
      },
      precipitation: {
        value: '.3 in',
        icon: 'Waves',
      },
    },
  },
}
