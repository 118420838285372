import React from "react";

import { Button, Card } from "../../components";

import css from "./trainers.module.css";

export default function TrainersCard({ trainer, openSidebar }) {
  const head = (
    <div className={css.head}>
      <img src={trainer?.avatar} alt={`${trainer?.fname} ${trainer?.lname}`} />

      <div>
        <h3>{trainer?.fname} {trainer?.lname}</h3>
        <p>{trainer?.email}</p>
      </div>
    </div>
  )

  const body = <div className={css.body}>{trainer?.about}</div>

  const footer = (
    <div className={css.foot}>
      <Button
        label='View schedule'
        onClick={() => openSidebar('schedule', trainer)}
        color='secondary'
      />

      <Button
        label='Make a payment'
        onClick={() => openSidebar('payment', trainer)}
        color='secondaryAlt'
      />
    </div>
  )
  
  return (
    <Card>
      {head}
      {trainer?.about && body}
      {footer}
    </Card>
  )
}
