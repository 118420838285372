import React, { useMemo, useState } from 'react'

import { trainers as trainersData } from '../../_fakeData'

import { Button, Sidebar } from '../../components'

import TrainersCard from './trainers-card'
import TrainersSchedule from './trainers-schedule'
import TrainersPayment from './trainers-payment'
import TrainersMethods from './trainers-methods'

import css from './trainers.module.css'

export default function Trainers() {
  const [showSidebar, setShowSidebar] = useState(false)
  const [sidebarType, setSidebarType] = useState(null)
  const [activeTrainer, setActiveTrainer] = useState(null)

  const onSidebarHide = () => {
    setSidebarType(null)
    setShowSidebar(false)
  }

  const openSidebar = (type, trainer) => {
    setSidebarType(type)
    setShowSidebar(true)
    setActiveTrainer(() => trainersData.find(t => t.id === trainer.id))
  }
  
  const eventsData = useMemo(() => [
    {
      title: 'All Day Event',
      start: '2025-02-10',
      extendedProps: {
        notification: '30 minutes before',
        trainer: 'John Doe',
        location: 'Main Hall',
        description: 'Dinner with friends',
        category: 'afterwork'
      },
    },
    {
      title: 'Long Event',
      start: '2025-01-07',
      end: '2025-02-10',
      extendedProps: {
        trainer: 'John Doe',
        location: 'Home',
        description: 'Vacation',
        category: 'pto'
      },
    },
    {
      groupId: 999,
      title: 'Repeating Event',
      start: '2025-02-10T16:00:00',
      extendedProps: {
        notification: '10 minutes before',
        trainer: 'Jane Doe',
        location: 'Conference Room',
        description: 'Standup',
        category: 'meeting',
        invites: [
          {
            fname: 'John',
            lname: 'Doe',
            status: 'accepted',
          },
          {
            fname: 'Jane',
            lname: 'Doe',
            status: 'accepted',
          },
          {
            fname: 'John',
            lname: 'Smith',
            status: 'declined',
          },
          {
            fname: 'Alice',
            lname: 'Johnson',
            status: 'pending',
          },
          {
            fname: 'Bob',
            lname: 'Brown',
            status: 'pending',
          },
          {
            fname: 'Charlie',
            lname: 'Davis',
            status: 'pending',
          }
        ],
      },
    },
    {
      groupId: 999,
      title: 'Repeating Event',
      start: '2025-02-17T16:00:00',
      extendedProps: {
        notification: '10 minutes before',
        trainer: 'Jane Doe',
        location: 'Conference Room',
        description: 'Standup',
        category: 'meeting'
      },
    },
    {
      title: 'Conference',
      start: '2025-02-11',
      end: '2025-02-13',
      extendedProps: {
        notification: '10 minutes before',
        trainer: 'Jane Doe',
        location: 'Conference Room',
        description: 'Standup',
        category: 'meeting'
      },
    },
    {
      title: 'Meeting',
      start: '2025-02-12T10:30:00',
      end: '2025-02-12T12:30:00',
      extendedProps: {
        notification: '10 minutes before',
        trainer: 'Jane Doe',
        location: 'Outside the office',
        description: 'Meeting to go over the project',
        category: 'meeting'
      },
    },
    {
      title: 'Lunch Meeting',
      start: '2025-02-12T12:00:00',
      extendedProps: {
        notification: '20 minutes before',
        trainer: 'Jane Doe',
        location: 'Conference Room',
        description: 'Lunch meeting with clients',
        category: 'meeting'
      },
    },
    {
      title: 'Meeting',
      start: '2025-02-12T14:30:00',
    },
    {
      title: 'Happy Hour',
      start: '2025-02-12T17:30:00',
      extendedProps: {
        notification: '10 minutes before',
        trainer: 'John Doe',
        location: 'All the bars',
        description: 'Bar crawl with coworkers',
        category: 'afterwork'
      },
    },
    {
      title: 'Dinner',
      start: '2025-02-12T20:00:00',
      end: '2025-02-12T23:00:00',
      extendedProps: {
        notification: '30 minutes before',
        trainer: 'John Doe',
        location: 'Main Hall',
        description: 'Dinner with friends',
        category: 'afterwork'
      },
    },
    {
      title: 'Birthday Party',
      start: '2025-02-13T07:00:00',
      extendedProps: {
        notification: '30 minutes before',
        trainer: 'John Doe',
        location: 'Main Hall',
        description: 'Dinner with friends',
        category: 'afterwork'
      },
    },
    {
      title: 'Click for Google',
      url: 'http://google.com/',
      start: '2025-02-28',
      extendedProps: {
        notification: '30 minutes before',
        trainer: 'John Doe',
        location: 'Main Hall',
        description: 'Dinner with friends',
        category: 'default'
      },
    },
  ], [])
  
  const getTrainers = trainersData.map((trainer, index) => (
    <TrainersCard
      key={index}
      trainer={trainer}
      openSidebar={openSidebar}
    />
  ))

  const btnSchedule = (
    <Button
      key="btnSchedule"
      onClick={() => alert('Schedule functionality')}
      label="Schedule training"
    />
  )

  const actions = sidebarType === 'schedule'
    ? [btnSchedule]
    : []

  const propsSidebar = () => ({
    footerbuttons: actions,
    headertitle: sidebarType === 'schedule'
      ? `${activeTrainer?.fname}'s Schedule`
      : `Make a payment to ${activeTrainer?.fname}`,
    onHide: () => onSidebarHide(),
    visible: showSidebar,
  })
  
  const sidebarContentMap = {
    schedule: (
      <TrainersSchedule
        schedule={eventsData}
        activeTrainer={activeTrainer}
      />
    ),
    payment: (
      <TrainersPayment
        onHide={() => onSidebarHide()}
        onClick={() => setSidebarType('default')}
        activeTrainer={activeTrainer}
      />
    ),
    default: (
      <TrainersMethods
        onHide={() => onSidebarHide()}
        onClick={() => setSidebarType('payment')}
      />
    )
  };

  const getSidebarContent = sidebarContentMap[sidebarType] || sidebarContentMap.default;

  const sidebar = (
    <Sidebar {...propsSidebar()}>
      {getSidebarContent}
    </Sidebar>
  )

  return (
    <div className={css.trainers}>
      <div className={css.cardSection}>
        {getTrainers}
        {sidebar}
      </div>
    </div>
  )
}
