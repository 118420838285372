import React from 'react'
import { useField } from 'formik'
import { Calendar as CalendarPR } from 'primereact/calendar'

import { FormErrorMessage } from '../../form-error-message/form-error-message'

import css from '../form-element.module.css'

export default function Calendar({ label, required, formik, size, ...props }) {
  const [field, meta] = useField(props?.name)
  const elWidth = `${size || '100'}%`

  const getStyles = () => {
    let styles = {}
    if (props.style) {
      styles = {
        ...props.style,
      }
    }
    return styles
  }

  const getLabel = label && (
    <label>
      {label}
      {required && <span>*</span>}
    </label>
  )

  const getPlaceholder = (timeOnly) => {
    if (timeOnly) {
      return null
    }
    return 'Select date'
  }

  const getElement = (
    <CalendarPR
      {...field}
      {...props}
      showIcon={props?.timeOnly ? false : true}
      iconPos='left'
      placeholder={getPlaceholder(props?.timeOnly)}
      className={css.calendar}
    />
  )

  return (
    <div
      className={`${css.formEl} ${meta.error && meta.touched ? css.error : ''}`}
      style={{
        width: elWidth,
        ...getStyles(),
      }}
    >
      {getLabel}
      {getElement}
      {required && <FormErrorMessage name={props.name} formik={formik} />}
    </div>
  )
}
