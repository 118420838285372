import React, { useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import { Checkbox } from 'primereact/checkbox'

import { Button, FormElement, Icon } from '../../components'

import css from './trainers.module.css'

export default function TrainersMethods({ onHide, onClick }) {

  const checkboxRef = useRef(null)

  const [selectedOption, setSelectedOption] = useState(null)

  const savedMethodsItems = [
    {
      id: 0,
      name: 'visa',
      label: 'Visa',
      icon: () => <Icon icon="Visa" />,
      lastFour: '1234',
    },
    {
      id: 1,
      name: 'mastercard',
      label: 'Mastercard',
      icon: () => <Icon icon="Mastercard" />,
      lastFour: '2345',
    },
    {
      id: 2,
      name: 'applepay',
      label: 'Apple Pay',
      icon: () => <Icon icon="ApplePay" />,
      lastFour: '3456',
    },
    {
      id: 3,
      name: 'stripe',
      label: 'Stripe',
      icon: () => <Icon icon="Stripe" />,
      lastFour: '4567',
    },
  ]
  
  const onMethodChange = e => {
    setSelectedOption(e.value)
  }

  return (
    <Formik>
      {formik => {

        const savedMethods = (
          <div className={css.savedMethods}>
            {savedMethodsItems.map((item) => (
              <div
                key={item?.id}
                className={css.method}
                onClick={() => setSelectedOption(item?.name)}
              >
                <div className={css.icon}>
                  {item?.icon()}
                </div>
                <div className={css.info}>
                  <p>{item?.label} ending in {item?.lastFour}</p>

                  <div className={css.actions}>
                    <Button
                      label='Set as default'
                      onClick={() => alert('Default functionality')}
                      link={true}
                      color='tertiary'
                      style={{
                        padding: 2,
                        marginLeft: -2
                      }}
                    />
                    <Button
                      label='Edit'
                      onClick={() => alert('Edit functionality')}
                      link={true}
                      color='tertiary'
                      style={{
                        padding: 2,
                        marginLeft: -2
                      }}
                    />
                  </div>
                </div>
                <Checkbox
                  ref={checkboxRef}
                  inputId={item?.name}
                  name={item?.name}
                  value={item?.name}
                  onChange={(e) => onMethodChange(e)}
                  checked={selectedOption === item?.name}
                />
              </div>
            ))}

            <Button
              label='+ Add payment method'
              onClick={() => alert('Add functionality')}
              link={true}
              color='tertiary'
            />
          </div>
        )

        const sep = <div data-hw-role="sep" />

        const notes = (
          <FormElement
            element='inputTextarea'
            id='notes'
            name='notes'
            formik={formik}
            label='Notes'
          />
        )

        const formEls = [savedMethods, sep, notes]

        const getContent =
          formEls &&
          formEls.map((el, index) => (
            <React.Fragment key={index}>{el}</React.Fragment>
          ))

        const body = (
          <div>
            <Button
              label='Enter a new payment method'
              onClick={onClick}
              color='tertiary'
            />
            {getContent}
          </div>
        )

        const btnCancel = (
          <Button key="btnCancel" onClick={onHide} label="Cancel" color='secondary' />
        )

        const btnConfirm = (
          <Button
            key="btnConfirm"
            onClick={() => alert('Confirm functionality')}
            label="Confirm"
          />
        )

        const footerbuttons = [btnCancel, btnConfirm]

        const footer = (
          <footer>
            {footerbuttons.map((button, index) => (
              <React.Fragment key={index}>{button}</React.Fragment>
            ))}
          </footer>
        )

        return (
          <Form className={css.methodsForm}>
            {body}
            {footer}
          </Form>
        )
      }}
    </Formik>
  )
}
