/** @jsxImportSource @emotion/react */
import Icon from '../../components/icon/icon'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import moment from 'moment'

import { appColors } from '../../emotion/appColors'

import { css as emotion } from './css'
import css from './stalls.module.css'

const StallCard = props => {
  const { data, onClick, setActiveStall, isActive, id } = props

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const horses = [
    ...new Set(
      data.horses_assigned?.map(h => {
        return h?.name
      }),
    ),
  ]
    .slice(0, 2)
    .join(' | ')

  const headerBlock = (
    <div className={css.cardHeader}>
      <h3>{data?.name}</h3>
      <span>{horses}</span>
    </div>
  )

  const horseImg = data?.image

  const imgBlock = horseImg ? (
    <div className={css.imgBlock} css={emotion.stallCard().imgBlock}>
      <img src={horseImg?.link} alt={data?.name || ''} />
    </div>
  ) : (
    <div className={`${css.imgBlock} ${css.plc}`} css={emotion.stallCard().plc}>
      <Icon icon="Horse2" fill={appColors?.grays?.[200]} />
    </div>
  )

  // const tagsBlock = <Tags />;

  const onClickStall = () => {
    setActiveStall(data)
  }

  return (
    <div
      id={id}
      // css={emotion.stallCard(isActive)}
      className={css.stallCard}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      <div className={css.holder} onClick={onClickStall}>
        {headerBlock}
        {imgBlock}

        {data?.image?.created_at && (
          <div className={css.date}>
            {moment(data?.image?.created_at).format('MM/DD/YYYY HH:mm')}
          </div>
        )}

        {/*{tagsBlock}*/}
      </div>
    </div>
  )
}
export default StallCard
