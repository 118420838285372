import React, { useEffect, useRef, useState } from 'react'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'

import css from './trainers.module.css'

export default function TrainersSchedule({ schedule }) {
  const [domText, setDomText] = useState('')

  const currentDate = new Date()
  const monthShort = currentDate.toLocaleString('default', { month: 'short' })
  const day = currentDate.getDate()
  
  const calendar = useRef(null)
  const calendarList = useRef(null)
  
  useEffect(() => {
    if (calendar.current) {
      let calendar1 = new Calendar(calendar.current, {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'title',
          center: 'dayGridMonth,timeGridWeek,listWeek',
          right: 'prev,today,next',
        },
        events: schedule,
        // eventClick: function (info) {
        //   if (!info?.event?.url) {
        //     info.jsEvent.preventDefault()
        //     setActiveEvent(info.event)
        //   }
        // },
      })
      
      calendar1.render()
    }

    if (calendarList.current) {
      let calendarList1 = new Calendar(calendarList.current, {
        plugins: [listPlugin],
        initialView: 'listDay',
        events: schedule,
        headerToolbar: {
          left: 'title',
          center: null,
          right: null
        },
      })

      calendarList1.render()
    }

    const domElement = document.getElementById('fc-dom-1') 
    if (domElement) {
      setDomText(domElement.textContent)

      const observer = new MutationObserver(() => {
        setDomText(domElement.textContent)
      })

      observer.observe(domElement, {
        childList: true,
        characterData: true,
        subtree: true,
      })

      return () => {
        observer.disconnect()
      }
    }
  }, [schedule])

  const monthBlock = (
    <div className={css.monthBlock}>
      <div className={css.today}>
        <div className={css.mon}>{monthShort}</div>
        <div className={css.day}>{day}</div>
      </div>
      <div className={css.monthInfo}>
        <div className={css.mon}>{domText}</div>
      </div>
    </div>
  )

  return (
    <>
      {monthBlock}
      <div id="calendar" className={css.calendar} ref={calendar} />
      <div id="calendarList" className={css.calendar} ref={calendarList} />
    </>
  )
}
