import React, { useState, useRef, useEffect, useMemo } from 'react'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'

import { Button, Sidebar } from '../../components'

import './css.css'
import css from './calendar.module.css'
import CalendarTray from './calendar-tray'
import CalendarEventDetail from './calendar-event-detail'

export default function CommunityCalendar() {
  const [showSidebar, setShowSidebar] = useState(false)
  const [activeEvent, setActiveEvent] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [domText, setDomText] = useState('')
  
  const calendar = useRef(null)

  const currentDate = new Date()
  const monthShort = currentDate.toLocaleString('default', { month: 'short' })
  const day = currentDate.getDate()
  
  const eventsData = useMemo(() => [
    {
      title: 'All Day Event',
      start: '2025-02-10',
      extendedProps: {
        notification: '30 minutes before',
        trainer: 'John Doe',
        location: 'Main Hall',
        description: 'Dinner with friends',
        category: 'afterwork'
      },
    },
    {
      title: 'Long Event',
      start: '2025-01-07',
      end: '2025-02-10',
      extendedProps: {
        trainer: 'John Doe',
        location: 'Home',
        description: 'Vacation',
        category: 'pto'
      },
    },
    {
      groupId: 999,
      title: 'Repeating Event',
      start: '2025-02-10T16:00:00',
      extendedProps: {
        notification: '10 minutes before',
        trainer: 'Jane Doe',
        location: 'Conference Room',
        description: 'Standup',
        category: 'meeting',
        invites: [
          {
            fname: 'John',
            lname: 'Doe',
            status: 'accepted',
          },
          {
            fname: 'Jane',
            lname: 'Doe',
            status: 'accepted',
          },
          {
            fname: 'John',
            lname: 'Smith',
            status: 'declined',
          },
          {
            fname: 'Alice',
            lname: 'Johnson',
            status: 'pending',
          },
          {
            fname: 'Bob',
            lname: 'Brown',
            status: 'pending',
          },
          {
            fname: 'Charlie',
            lname: 'Davis',
            status: 'pending',
          }
        ],
      },
    },
    {
      groupId: 999,
      title: 'Repeating Event',
      start: '2025-02-17T16:00:00',
      extendedProps: {
        notification: '10 minutes before',
        trainer: 'Jane Doe',
        location: 'Conference Room',
        description: 'Standup',
        category: 'meeting'
      },
    },
    {
      title: 'Conference',
      start: '2025-02-11',
      end: '2025-02-13',
      extendedProps: {
        notification: '10 minutes before',
        trainer: 'Jane Doe',
        location: 'Conference Room',
        description: 'Standup',
        category: 'meeting'
      },
    },
    {
      title: 'Meeting',
      start: '2025-02-12T10:30:00',
      end: '2025-02-12T12:30:00',
      extendedProps: {
        notification: '10 minutes before',
        trainer: 'Jane Doe',
        location: 'Outside the office',
        description: 'Meeting to go over the project',
        category: 'meeting'
      },
    },
    {
      title: 'Lunch Meeting',
      start: '2025-02-12T12:00:00',
      extendedProps: {
        notification: '20 minutes before',
        trainer: 'Jane Doe',
        location: 'Conference Room',
        description: 'Lunch meeting with clients',
        category: 'meeting'
      },
    },
    {
      title: 'Meeting',
      start: '2025-02-12T14:30:00',
    },
    {
      title: 'Happy Hour',
      start: '2025-02-12T17:30:00',
      extendedProps: {
        notification: '10 minutes before',
        trainer: 'John Doe',
        location: 'All the bars',
        description: 'Bar crawl with coworkers',
        category: 'afterwork'
      },
    },
    {
      title: 'Dinner',
      start: '2025-02-12T20:00:00',
      end: '2025-02-12T23:00:00',
      extendedProps: {
        notification: '30 minutes before',
        trainer: 'John Doe',
        location: 'Main Hall',
        description: 'Dinner with friends',
        category: 'afterwork'
      },
    },
    {
      title: 'Birthday Party',
      start: '2025-02-13T07:00:00',
      extendedProps: {
        notification: '30 minutes before',
        trainer: 'John Doe',
        location: 'Main Hall',
        description: 'Dinner with friends',
        category: 'afterwork'
      },
    },
    {
      title: 'Click for Google',
      url: 'http://google.com/',
      start: '2025-02-28',
      extendedProps: {
        notification: '30 minutes before',
        trainer: 'John Doe',
        location: 'Main Hall',
        description: 'Dinner with friends',
        category: 'default'
      },
    },
  ], [])

  useEffect(() => {
    if (calendar.current) {
      let calendar1 = new Calendar(calendar.current, {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'title',
          center: 'prev,today,next',
          right: 'dayGridMonth,timeGridWeek,listWeek',
        },
        events: eventsData,
        eventClick: function (info) {
          if (!info?.event?.url) {
            info.jsEvent.preventDefault()
            setShowSidebar(true)
            setActiveEvent(info.event)
          }
        },
        eventDidMount: function(info) {
          // console.log('eventDidMount', info?.event?.extendedProps)
        },
        eventClassNames: function(arg) {
          // console.log('--arg', arg)
          if (arg?.event?.extendedProps?.category === 'meeting') {
            return 'meeting'
          } else if (arg?.event?.extendedProps?.category === 'pto') {
            return 'pto'
          } else if (arg?.event?.extendedProps?.category === 'afterwork') {
            return 'afterwork'
          } else {
            return 'default'
          }
        }
      })
      calendar1.render()
    }

    const domElement = document.getElementById('fc-dom-1')
    if (domElement) {
      setDomText(domElement.textContent)

      const observer = new MutationObserver(() => {
        setDomText(domElement.textContent)
      })

      observer.observe(domElement, {
        childList: true,
        characterData: true,
        subtree: true,
      })

      return () => {
        observer.disconnect()
      }
    }
  }, [eventsData])

  const handleAddEvent = () => {
    setShowSidebar(true)
  }

  const propsSidebar = () => ({
    footerbuttons: [],
    headertitle: !activeEvent
      ? 'Add event'
      : isEdit
      ? 'Edit event'
      : 'Event details',
    onHide: () => {
      setShowSidebar(false)
      setActiveEvent(false)
      setIsEdit(false)
    },
    visible: showSidebar,
    blockScroll: true,
  })

  const sidebar = (
    <Sidebar {...propsSidebar()}>
      {!activeEvent ? (
        <CalendarTray />
      ) : isEdit ? (
        <CalendarTray event={activeEvent} />
      ) : (
        <CalendarEventDetail event={activeEvent} setIsEdit={setIsEdit} />
      )}
    </Sidebar>
  )

  const monthBlock = (
    <div className={css.monthBlock}>
      <div className={css.today}>
        <div className={css.mon}>{monthShort}</div>
        <div className={css.day}>{day}</div>
      </div>
      <div className={css.monthInfo}>
        <div className={css.mon}>{domText}</div>
      </div>
    </div>
  )

  const btnAddEvent = <Button label="+ Add event" onClick={handleAddEvent} />

  const calendarBlock = (
    <div className={css.calendarBlock}>
      {monthBlock}
      {btnAddEvent}
      <div id="calendar" className={css.calendarEl} ref={calendar} />
    </div>
  )

  return (
    <div className={css.calendar}>
      {calendarBlock}
      {sidebar}
    </div>
  )
}
