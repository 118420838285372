import React from "react";

import { ButtonIcon, Icon } from "../../components";

import css from './calendar.module.css'

export default function CalendarEventDetail({
  event,
  setIsEdit,
}) {

  const evtTitle = event?._def?.title
  const evtStart = event?._instance?.range?.start
  const evtEnd = event?._instance?.range?.end
  const evtNotification = event?.extendedProps?.notification

  const evtInvites = event?.extendedProps?.invites
  const numberOfInvites = evtInvites ? evtInvites.length : 'No '

  const evtDescription = event?.extendedProps?.description

  const getDates = (date) => {
    const dateObj = new Date(date)
    const dow = dateObj.toLocaleString('default', { weekday: 'long' })
    const day = dateObj.getDate()
    const monthShort = dateObj.toLocaleString('default', { month: 'short' })
    const year = dateObj.getFullYear()
    const formattedDate = `${dow}, ${monthShort} ${day}, ${year}`
    return formattedDate
  }

  const getTimes = (date) => {
    const dateObj = new Date(date)
    const formattedTime = dateObj.toLocaleTimeString('default', { hour: '2-digit', minute: '2-digit' })
    return formattedTime
  }

  const handleCopy = () => {
    alert('copy functionality')
    console.log('copy')
  }

  const handleTrash = () => {
    alert('trash functionality')
    console.log('trash')
  }

  const getInitialState = (name) => name ? name.charAt(0).toUpperCase() : '';

  const btnCopy = (
    <ButtonIcon
      icon={<Icon icon='Copy' />}
      onClick={handleCopy}
    />
  )

  const btnTrash = (
    <ButtonIcon
      icon={<Icon icon='Trash' />}
      onClick={handleTrash}
    />
  )

  const btnEdit = (
    <ButtonIcon
      icon={<Icon icon='Edit' />}
      onClick={setIsEdit}
    />
  )

  const actions = [btnCopy, btnTrash, btnEdit]

  const details = (
    <div className={css.details}>
      <div className={css.header}>
        <div className={css.title}>{evtTitle}</div>
        <div className={css.actions}>
          {actions.map((action, i) => (
            <React.Fragment key={i}>{action}</React.Fragment>
          ))}
        </div>
      </div>

      <div className={css.info}>
        <div className={css.item}>
          <Icon icon='Calendar' fill='#98A2B3' />
          <div>
            {getDates(evtStart)}
            {evtEnd && ` - ${getDates(evtEnd)}`}
          </div>
        </div>

        <div className={css.item}>
          <Icon icon='Clock' />
          {getTimes(evtStart)}
          {evtEnd && ` - ${getTimes(evtEnd)}`}
        </div>

        {evtNotification && (
          <div className={css.item}>
            <Icon icon='BellRinging' />
            {evtNotification}
          </div>
        )}
      </div>
    </div>
  )

  const avatars = (
    <div className={css.avatars}>
      {evtInvites && evtInvites.map((invite, i) => (
        <div key={i} className={css.avatar}>
          {invite?.avatar ? (
            <img src={invite.avatar} alt={`${invite?.fname} ${invite?.lname}`} />
          ) : (
            <div className={css.initials}>
              {getInitialState(invite?.fname)}
              {getInitialState(invite?.lname)}
            </div>
          )}
        </div>
      ))}

      <div className={css.more} onClick={() => alert('add functionality')}>
        <Icon icon='Plus' />
      </div>

      <div className={css.numbers}>
        <div className={css.num}>
          {numberOfInvites} guests
        </div>

        {evtInvites && (
          <>
            <div className={css.num}>
              {evtInvites.filter(invite => invite.status === 'accepted').length} yes
            </div>
            <div className={css.num}>
              {evtInvites.filter(invite => invite.status === 'declined').length} no
            </div>
            <div className={css.num}>
              {evtInvites.filter(invite => invite.status === 'pending').length} awaiting
            </div>
          </>
        )}
      </div>
    </div>
  )

  const supporting = evtDescription && (
    <div className={css.supporting}>
      <h2>About this event</h2>
      {evtDescription}
    </div>
  )

  return (
    <div className={css.eventDetails}>
      {details}
      {avatars}
      {supporting}
    </div>
  )
}
