import { ReactComponent as ApplePay } from './assets/apple-pay.svg'
import { ReactComponent as ArrowUp } from './assets/arrow-up.svg'
import { ReactComponent as ArrowDown } from './assets/arrow-down.svg'
import { ReactComponent as BellRinging } from './assets/bell-ringing.svg'
import { ReactComponent as Calendar } from './assets/calendar.svg'
import { ReactComponent as CheckIcon } from './assets/check-icon.svg'
import { ReactComponent as ChevronLeft } from './assets/chevron-left.svg'
import { ReactComponent as ChevronRight } from './assets/chevron-right.svg'
import { ReactComponent as Clock } from './assets/clock.svg'
import { ReactComponent as Copy } from './assets/copy.svg'
import { ReactComponent as Droplets } from './assets/droplets.svg'
import { ReactComponent as Edit } from './assets/edit.svg'
import { ReactComponent as Eye } from './assets/eye.svg'
import { ReactComponent as EyeClosed } from './assets/eye-closed.svg'
import { ReactComponent as FullScreen } from './assets/fullscreen.svg'
import { ReactComponent as Horse } from './assets/horse.svg'
import { ReactComponent as Horse2 } from './assets/horse2.svg'
import { ReactComponent as Logo } from './assets/logo.svg'
import { ReactComponent as Logout } from './assets/logout.svg'
import { ReactComponent as Mastercard } from './assets/mastercard.svg'
import { ReactComponent as Moon2 } from './assets/moon2.svg'
import { ReactComponent as Moonrise } from './assets/moonrise.svg'
import { ReactComponent as Moonset } from './assets/moonset.svg'
import { ReactComponent as NavLogo } from './assets/nav-logo.svg'
import { ReactComponent as NavLogoHW } from './assets/nav-logo-hw.svg'
import { ReactComponent as Plus } from './assets/plus.svg'
import { ReactComponent as Restore } from './assets/restore.svg'
import { ReactComponent as Settings } from './assets/settings.svg'
import { ReactComponent as Signout } from './assets/signout.svg'
import { ReactComponent as Stall } from './assets/stall.svg'
import { ReactComponent as Stripe } from './assets/stripe.svg'
import { ReactComponent as Sun } from './assets/sun.svg'
import { ReactComponent as Sunrise } from './assets/sunrise.svg'
import { ReactComponent as Sunset } from './assets/sunset.svg'
import { ReactComponent as Trash } from './assets/trash.svg'
import { ReactComponent as TrayTab } from './assets/tray-tab.svg'
import { ReactComponent as Upload } from './assets/upload.svg'
import { ReactComponent as User } from './assets/user.svg'
import { ReactComponent as User2 } from './assets/user2.svg'
import { ReactComponent as Video } from './assets/video.svg'
import { ReactComponent as Visa } from './assets/visa.svg'
import { ReactComponent as Waves } from './assets/waves.svg'
import { ReactComponent as Wind2 } from './assets/wind2.svg'
import { ReactComponent as X } from './assets/x.svg'

export const icons = {
  ArrowUp,
  ArrowDown,
  ApplePay,
  BellRinging,
  Calendar,
  CheckIcon,
  ChevronLeft,
  ChevronRight,
  Clock,
  Copy,
  Droplets,
  Eye,
  EyeClosed,
  Edit,
  FullScreen,
  Horse,
  Horse2,
  Logo,
  Logout,
  Mastercard,
  Moon2,
  Moonrise,
  Moonset,
  NavLogo,
  NavLogoHW,
  Restore,
  Plus,
  Settings,
  Signout,
  Stall,
  Stripe,
  Sun,
  Sunrise,
  Sunset,
  Trash,
  TrayTab,
  Upload,
  User,
  User2,
  Video,
  Visa,
  Waves,
  Wind2,
  X,
}
