import React from 'react'
import { Button as ButtonPR } from 'primereact/button'

import css from './button.module.css'

export default function Button({
  color,
  ...props
}) {

  const cssButton = [css.button, css[color]].join(' ')

  return (
    <ButtonPR
      {...props}
      className={cssButton}
    />
  )
}
