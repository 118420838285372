import React from 'react'

import { Icon } from '../../components'

import { weatherData as stats } from '../../_fakeData'

import css from './weather-block.module.css'

export default function WeatherBlock() {

  const handleBackClick = () => {
    alert('Back')
    console.log('Back')
  }

  const handleForwardClick = () => {
    alert('Forward')
    console.log('Forward')
  }

  const mainData = (
    <div className={css.maindata}>
      <div className={css.city}>Woodinville</div>

      <div className={css.time}>
        <div onClick={handleBackClick}>
          <Icon icon="ChevronLeft" fill='#386A81' />
        </div>
        Now
        <div onClick={handleForwardClick}>
          <Icon icon="ChevronRight" fill='#386A81' />
        </div>
      </div>
      
      <div className={css.weather}>
        <Icon icon="Sun" />
        <h2>Clear</h2>
      </div>
    </div>
  )

  const currentTemp = (
    <div className={css.currenttemp}>
      <h2>35°F</h2>
      <h3>Feels like 28°</h3>
    </div>
  )

  const getStats = obj => {

    const objects = stats?.[obj]

    const getStats = Object.keys(objects).map((key, index) => (
      <div key={index} className={css.stack}>
        {Object.keys(objects[key]).map((subKey, subIndex) => {

          const iconOrLabel = objects[key][subKey]?.icon
            ? <Icon icon={objects[key][subKey]?.icon} />
            : <>{objects[key][subKey]?.label}:</>
          
          return (
            <div key={subIndex}>
              {iconOrLabel}
              <h2>{objects[key][subKey]?.value}{objects[key][subKey]?.degree && <>&deg;</>}</h2>
            </div>
          )
        })}
      </div>
    ))

    return getStats
  }
  

  const details = (
    <div className={css.details}>
      <div className={css.left}>
        {currentTemp}
      </div>
      <div className={css.center}>
        {getStats('center')}
      </div>
      <div className={css.right}>
        {getStats('right')}
      </div>
    </div>
  )

  return (
    <div className={css.weatherbox}>
      {mainData}
      {details}
    </div>
  )
}
