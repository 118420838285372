import React, { useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import Header from '../header/header'
import GlobalBanner from '../global-banner/global-banner'

import css from './main.module.css'

const Main = () => {
  const loc = useLocation()

  const [activePage, setActivePage] = useState(loc.pathname.slice(1))
  const [showGlobalBanner, setShowGlobalBanner] = useState(false)

  const plcBanner = [
    {
      id: 1,
      category: 'warning',
      text: 'Weather warning',
      subtext: 'Temperature below 50ºF',
    },
    {
      id: 2,
      category: 'reminder',
      text: 'Appointment reminder',
      subtext: 'Vet checkup for Daisy at 1:30pm today',
    },
  ]

  const globalBanner = showGlobalBanner ? (
    <GlobalBanner
      onClick={setShowGlobalBanner}
      category={plcBanner[0].category}
      text={plcBanner[0].text}
      subtext={plcBanner[0].subtext}
    />
  ) : null

  return (
    <div className={`site ${css.site}`}>
      <Header activePage={activePage} setActivePage={setActivePage} />
      {globalBanner}
      <main className={css.main}>
        <Outlet context={[activePage, setActivePage]} />
      </main>
    </div>
  )
}

export default Main
