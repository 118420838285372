import React, { useEffect } from "react";
import { Sidebar as PrSidebar } from "primereact/sidebar";

import Icon from "../icon/icon";

import css from "./sidebar.module.css";

const Sidebar = ({
  children,
  headertitle,
  footerbuttons = [],
  footerButtonsInside = false,
  ...props
}) => {

  useEffect(() => {
    const headerElement = document.querySelector('.site > header')

    if (props?.modal === false) {
      headerElement.style.zIndex = '1102'
    } else {
      headerElement.style.zIndex = '100'
    }
  }, [props?.modal])

  const sidebarContent = ({closeIconRef, hide, children}) => {
    const header = props?.modal !== false ? (
      <div id='header' className={css.header}>
        {headertitle && <h3>{headertitle}</h3>}
        <div
          ref={closeIconRef}
          onClick={(e) => hide(e)}
          className={css.closeIcon}
        >
          <Icon icon="X" fill="#000000" />
        </div>
      </div>
    ) : (
      <div className={css.chevronClose} onClick={(e) => hide(e)}>
        <Icon icon="ChevronRight" fill='#000000' />
      </div>
    )

    const footer = (
      <div className={css.footer}>
        {footerbuttons.map((button, index) => (
          <React.Fragment key={index}>
            {button}
          </React.Fragment>
        ))}
      </div>
    )
    
    return (
      <>
        {header}

        <div id='content' className={css.content}>
          {children}
        </div>

        {footerbuttons.length > 0 && !footerButtonsInside && footer}
      </>
    )
  }

  return (
    <PrSidebar
      {...props}
      position='right'
      content={({closeIconRef, hide}) => sidebarContent({closeIconRef, hide, children})}
      className={`${css.sidebar} ${props?.modal === false ? 'inline' : ''}`}
    />
  );
};

export default Sidebar;
