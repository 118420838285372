import React, { useEffect, useRef } from 'react'
import { Calendar } from '@fullcalendar/core'
import listPlugin from '@fullcalendar/list'

import { useQuery } from '../../hooks'

import { Card, WeatherBlock } from '../../components'

import StallSection from '../stalls/stall-section/stall-section'

import { barnNotes as notes, events as eventsData } from '../../_fakeData'

import css from './home.module.css'

export default function Home() {
  const { data } = useQuery(`/stalls`)

  const calendarList = useRef(null)

  useEffect(() => {
    if (calendarList.current) {
      let calendarList1 = new Calendar(calendarList.current, {
        plugins: [listPlugin],
        initialView: 'listDay',
        events: eventsData,
        headerToolbar: {
          left: null,
          center: null,
          right: null,
        },
      })

      calendarList1.render()
    }
  }, [calendarList])

  const stalls = (
    <div className={css.stalls}>
      <StallSection data={data?.data} />
    </div>
  )

  const weather = <WeatherBlock />

  const graph = (
    <Card title="Popular times">
      <p>graph here</p>
    </Card>
  )

  const todayBarn = (
    <Card title="Today at the barn">
      <div id="calendarList" className={css.calendar} ref={calendarList} />
    </Card>
  )

  const barnNotes = (
    <Card title="Barn notess">
      <ul>
        {notes.map((note, i) => (
          <li key={i}>{note}</li>
        ))}
      </ul>
    </Card>
  )

  const dashboard = (
    <div className={css.dashboard}>
      {weather}
      {graph}
      {todayBarn}
      {barnNotes}
    </div>
  )

  return (
    <div id='home' className={css.home}>
      {dashboard}
      {stalls}
    </div>
  )
}
