import React from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Button, FormElement } from '../../components'

import css from './calendar.module.css'

export default function CalendarTray({ event, onHide }) {

  const eventStart = event?._instance?.range?.start
  const eventEnd = event?._instance?.range?.end
  const eventNotification = event?._def?.extendedProps?.notification
  const eventTrainer = event?._def?.extendedProps?.trainer
  const eventLocation = event?._def?.extendedProps?.location
  const eventDescription = event?._def?.extendedProps?.description
  const eventGuests = event?._def?.extendedProps?.guests

  const initialValues = {
    title: event?._def?.title || '',
    date: eventStart || '',
    timeStart: eventStart || '',
    timeEnd: eventEnd || '',
    notification: eventNotification || '',
    trainer: eventTrainer ||'',
    location: eventLocation || '',
    description: eventDescription || '',
    guestEmails: eventGuests || [],
  }

  const validationSchema = Yup.object({
    title: Yup.string().label('Add title').required(),
    date: Yup.string().label('Date').required(),
    timeStart: Yup.string().label('Time').required(),
    timeEnd: Yup.string().label('').required(),
    notification: Yup.string().label('Notification'),
    trainer: Yup.string().label('Trainer'),
    location: Yup.string().label('Location'),
    description: Yup.string().label('Description'),
    guestEmails: Yup.array().label('Guest emails'),
  })

  const onSubmit = () => {
    alert('submit functionality')
    console.log('submit')
  }

  const formikProps = {
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validationSchema,
  }

  return (
    <Formik {...formikProps}>
      {(formik) => {

        const title = (
          <FormElement
            element='input'
            id='title'
            name='title'
            label='Add title'
            placeholder='Event name'
            formik={formik}
            required
          />
        )
        
        const date = (
          <FormElement
            element='calendar'
            id='date'
            name='date'
            label='Date'
            formik={formik}
            required
            style={{ flex: 1 }}
          />
        )

        const timeStart = (
          <FormElement
            element='calendar'
            id='timeStart'
            name='timeStart'
            label='Time'
            formik={formik}
            required
            timeOnly
            hourFormat='12'
          />
        )

        const timeEnd = (
          <FormElement
            element='calendar'
            id='timeEnd'
            name='timeEnd'
            label='Time'
            formik={formik}
            required
            timeOnly
            hourFormat='12'
          />
        )

        const timeSlots = (
          <div className={css.timeslots}>
            {timeStart}
            <div className={css.sep} />
            {timeEnd}
          </div>
        )

        const notification = (
          <FormElement
            element='dropdown'
            id='notification'
            name='notification'
            formik={formik}
            label='Notification'
            placeholder='Select notification...'
            options={['5 minutes', '10 minutes', '15 minutes']}
          />
        )

        const trainer = (
          <FormElement
            element='dropdown'
            id='trainer'
            name='trainer'
            formik={formik}
            label='Add trainer'
            placeholder='Select trainer...'
            options={['John Doe', 'Jane Doe']}
          />
        )

        const location = (
          <FormElement
            element='input'
            id='location'
            name='location'
            label='Add location'
            placeholder='Location'
            formik={formik}
          />
        )

        const description = (
          <FormElement
            element='inputTextarea'
            id='description'
            name='description'
            label='Add description'
            placeholder='Description'
            formik={formik}
          />
        )

        const guestEmails = (
          <FormElement
            element='input'
            id='guestEmails'
            name='guestEmails'
            label='Add guests by email'
            placeholder='email@horsewise.ai, email@gmail.com'
            formik={formik}
          />
        )

        const btnSendInvites = (
          <Button
            onClick={() => alert('Send invites functionality')}
            label="Send invites"
            color='secondaryAlt'
          />
        )

        const formEls = [
          title,
          date,
          timeSlots,
          notification,
          trainer,
          location,
          description,
          guestEmails,
          
          btnSendInvites
        ]

        const getContent = formEls && formEls.map((el, index) => (
          <React.Fragment key={index}>
            {el}
          </React.Fragment>
        ))

        const body = (
          <div>
            {getContent}
          </div>
        )

        const btnCancel = (
          <Button
            key="btnCancel"
            onClick={onHide}
            label="Cancel"
            color='secondary'
            style={{ flex: 1 }}
          />
        )
        
        const btnSave = (
          <Button
            key="btnSave"
            onClick={() => alert('Save functionality')}
            label="Save"
            style={{ flex: 1 }}
          />
        )

        const footerbuttons = [btnCancel, btnSave]

        const footer = (
          <footer>
            {footerbuttons.map((button, index) => (
              <React.Fragment key={index}>{button}</React.Fragment>
            ))}
          </footer>
        )

        return (
          <Form>
            {body}
            {footer}
          </Form>
        )
      }}
    </Formik>
  )
}
