import React, { useState } from 'react'

import Menu from '../menu/menu'
import Logo from '../logo/logo'

import css from './header.module.css'

export default function Header({
  activePage,
  setActivePage,
  hideMenu = false,
}) {
  const [navOpen, setNavOpen] = useState(false)

  const menu = (
    <Menu
      activePage={activePage}
      setActivePage={setActivePage}
      navOpen={navOpen}
      setNavOpen={setNavOpen}
      hideMenu={hideMenu}
    />
  )

  return (
    <header className={css.header}>
      <Logo />
      {menu}
    </header>
  )
}
