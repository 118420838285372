/** @jsxImportSource @emotion/react */
import { useEffect, useState, useCallback } from 'react';
import StallCard from '../stall-card';
import { DndContext, KeyboardSensor, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import {SortableContext, arrayMove} from '@dnd-kit/sortable';
import {useDroppable} from '@dnd-kit/core';
import Client from '../../../client';
import _ from "lodash";
import { useMutation } from '../../../hooks/use-mutation';

// import { css } from './css';
import { fullWidth } from '../../../emotion/utils';

import css from './stall-section.module.css';

const StallSection = (props) => {
  const {
    data = [],
    activeStall,
    setActiveStall=()=>{},
    className = ''
  } = props;

  const {setNodeRef} = useDroppable({
    id: 'unique-id',
  });
  const [items, setItems] = useState([])

  useEffect(() => {
    if(data && data.length > 0)
      setItems(data?.map((s) => s.id));
  }, [data])

  const sortOrderDifferent = useCallback((newItems) => {
    const oldItems = data?.map((d) => {
      return d.id
    })

    return !_.isEqual(oldItems, newItems)
  }, [data]);

  const { mutate: updateStallOrder } = useMutation({
    mutationFn: (stalls) => Client.put("/stalls/stall-order", { stalls })
  })

  useEffect(() => {
    if(items && items.length > 0) {
      const data = items.map((item, idx) => {
        return {
            id:item,
            sort_order:idx
          }
        }
      )
      const putIt = async (data) => {
        await updateStallOrder(data);
      }
      if(data && sortOrderDifferent)
        putIt(data)
    }
  }, [items, sortOrderDifferent, updateStallOrder])
  const stallData = data;
  
  function handleDragEnd(event) {
    if(event.active.id !== event.over.id) {
      const oldIdx = items.indexOf(event.active.id)
      const newIdx = items.indexOf(event.over.id)
      const newItems = arrayMove(items, oldIdx, newIdx)
      setItems(newItems)
    }
  }

  const stallsBlock = items
    ?.map(item => stallData.find((stall) => stall.id === item))
    .filter(s => !!s)
    .map(stall => (
      <StallCard
        key={stall.id}
        data={stall}
        id={stall.id}
        setActiveStall={() => setActiveStall(stall)}
        isActive={activeStall?.id === stall?.id}
      />
    ))

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10, // Enable sort function when dragging 10px
    },
  })
  const keyboardSensor = useSensor(KeyboardSensor)
  const sensors = useSensors(mouseSensor, keyboardSensor)
    
  return (
    <DndContext onDragEnd={handleDragEnd} sensors={sensors} style={{ ...fullWidth() }}>
      <div
        className={`${className} ${css.cardSection}`}
        id="droppable"
        ref={setNodeRef}
      >
        <SortableContext items={items}>
          {stallsBlock}
        </SortableContext>
      </div>
    </DndContext>
  )
}
export default StallSection;