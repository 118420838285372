/**@jsxImportSource @emotion/react */
import { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useMutation } from '../../hooks';
import { useQuery } from "../../hooks";
import Client from '../../client';
import AuthContext from "../../context/auth-context";
import { useToastContext } from "../../context/toast-context";

import CustomDivTable from "../../components/custom-table/custom-div-table";
import NavigationActionBar from "../../components/navigation-action-bar/navigation-action-bar";
import EditUserModal from "../../components/modals/EditUserModal";
import CustomDivTableActions from "../../components/custom-table/custom-div-table-actions";
import ButtonIcon from "../../components/button-icon/button-icon";
import Icon from "../../components/icon/icon";
import Button from "../../components/button/button";

import ShowArchiveUserModal from "./show-archive-user-modal";

import { fullWidth } from "../../emotion/utils";
import { appColors } from '../../emotion/appColors';
import { css } from "./css";

const userTabs = [
  { label: 'All', url: '/users?archived=all' },
  { label: 'Active', url: '/users?archived=false' },
  { label: 'Archived', url: '/users?archived=true' },
];

const actions = ['Add a new User'];

export default function Users() {
  const { showToast } = useToastContext();
  const [archiveUserModal, setArchiveUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [rows, setRows] = useState([])
  const [params, setParams] = useSearchParams();
  const visibility = params.get('archived');

  const {userData} = useContext(AuthContext)

  useEffect(() => {
    if (!visibility) {
      params.set('archived', 'false');
      setParams(params);
    }
  }, [visibility, params, setParams]);

  const activeUser = useRef(null);

  const navigate = useNavigate();

  let usersUrl = '/users';
  console.log("Visibility: ", visibility)
  if (!visibility || visibility === 'false') {
    usersUrl += '?archived=false';
  } else if (visibility === 'true') {
    usersUrl += '?archived=true';
  }

  const { data, refetch } = useQuery(usersUrl)

  //If user data changes (change barn they are looking at), refresh
  useEffect(() => {
    refetch()
  }, [refetch, userData])

  useEffect(() => {
    setRows(data?.data)
  }, [data])

  const { mutate: resendInvite } = useMutation({
    mutationFn: (userId) => Client.post(`/users/${userId}/resend-invite`, {}),
    onSuccess: () => {
      showToast({
        severity: "info",
        summary: "Success",
        detail: "Invitation Resent",
        life: 3000,
      })
    }
  })

  const { mutate: resetPassword } = useMutation({
    mutationFn: (email) => Client.post(`/auth/forgot-password`, { email }),
    onSuccess: () => {
      showToast({
        severity: "info",
        summary: "Success",
        detail: "Forgot Passsword email sent",
        life: 3000,
      })
    }
  })

  const onEdit = (data) => {
    activeUser.current = data;
    setShowEditUserModal(true);
  };

  const onDelete = (data) => {
    activeUser.current = data;
    setArchiveUserModal(true);
  };

  const handleToUser = (data) => {
    activeUser.current = data;
    navigate(`/user/${data.id}`);
  }

  const columns = [
    {
      name: "name",
      default: () => "",
      valFn: (data) => `${data.first_name ? data.first_name + ' ' + data.last_name : "--"}`,
      display: "Name",
    },
    {
      name: "role",
      default: () => "",
      valFn: (data) => {
        let roles = "";
        
        if (data?.is_admin) roles += "Admin, "
        if (data.is_barn_manager) roles += "Barn Manager, "
        if (data.is_vet) roles += "Vet, "
        if(data.horses_owned?.length > 0) roles += "Guardian, "
        if(roles.length > 2)return roles.substring(0, roles.length-2)
        
        return null;
      },
      display: "Role",
    },
    {
      name: "stall_num",
      default: () => "",
      valFn: (data) => {
        if (!data?.horses_owned?.length) return "--";
        const uniqueNames = [...new Set(data.horses_owned.map(s => s.stall_assigned_to?.name))];
        return uniqueNames.join(', ');
      },
      display: "Stall Number(s)",
    },
    {
      name: "email",
      default: () => "",
      valFn: (data) => `${data.email ? data.email : "--"}`,
      display: "Email",
    },
    {
      name: "actions",
      default: () => "",
      valFn: (data) => {
        const inviteBtn = !data.invitation_accepted
          ? (
            <Button
              label={data?.last_log_in ? "Reset Password" : "Resend Invite"}
              onClick={(e) => {
                e.stopPropagation();
                if(window.confirm(`Are you sure you want to send an email to ${data.email}?`))
                  if(data?.last_log_in) {
                    resetPassword(data.email)
                  } else {
                    resendInvite(data.id) 
                  }
              }}
              isTableBtn={true}
              color='secondary'
              style={{ marginRight: 6 }}
            />
          )
          : null;

        return (
          <CustomDivTableActions>
            {inviteBtn}    
            <ButtonIcon 
              icon={<Icon icon={data.archived_at ? "Restore" : "Trash"} stroke={null} fill={appColors?.grays?.[510]} />}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(data)
              }}
            />
            {/* <ButtonIcon
              icon={<Icon icon="Edit" stroke={null} fill={appColors?.grays?.[510]} />}
              onClick={() => onEdit(data)}
            /> */}
            <ButtonIcon
              icon={<Icon icon="ChevronRight" stroke={null} fill={appColors?.grays?.[510]} />}
              onClick={() => onEdit(data)}
            />
          </CustomDivTableActions>
        );
      },
    },
  ];

  const modals = (
    <>
      {archiveUserModal && (
        <ShowArchiveUserModal
          setShowModal={setArchiveUserModal}
          user={activeUser.current}
          onSuccess={() => {activeUser.current=null;refetch()}}
        />
      )}
      
      {showEditUserModal && (
        <EditUserModal
          setShowModal={setShowEditUserModal}
          user={activeUser.current}
          onSuccess={() => {activeUser.current=null;refetch()}}
        />
      )}
    </>
  );

  console.log("Rows", rows)
  return (
    <div css={css}>
      <NavigationActionBar
        defaultTab="Active"
        tabs={userTabs}
        refetch={refetch}
        actions={actions}
        onActionClick={action => {activeUser.current=null; setShowEditUserModal(action === 'Add a new User')}}
      />

      <div style={{ ...fullWidth() }}>
      <CustomDivTable
        columns={columns}
        rows={rows || []}
        selectionMode="single"
        onSelect={(it) => onEdit(it)}
        filterKeys={["first_name", "last_name", "email"]}
        showSearch={false}
      />

      {modals}
      </div>
    </div>
  );
}